export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  }, {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'GridIcon',
  },
  {
    title: 'User',
    icon: 'UserIcon',
    children: [
      {
        title: 'Users',
        route: 'User',
      }, {
        title: 'User Create',
        route: 'CreateUser',
      }],
  },
  {
    title: 'Companies',
    route: 'company',
  },
  {
    title: 'Employees',
    route: 'person',
  },
  {
    title: 'Job Categories',
    route: 'Category',
  },
  {
    title: 'Roles',
    route: 'Role',
    icon: 'KeyIcon',
  },
  {
    title: 'Settings',
    route: 'Settings',
    icon: 'SettingsIcon',
  },

]
