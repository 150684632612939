<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1  d-lg-flex">
      <dark-Toggler class="d-lg-block" />
    </div>
    <b-navbar-nav
      v-if="isAuth"
      class="nav align-items-center ml-auto"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ auth.name }}
            </p>
            <span class="user-status">{{ $t('Online') }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="$fullImagePath( authAvatar )"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({name:'home'})"
        >
          <fa-icon
            icon="home"
            class="mr-50"
            pack="fas"
          />
          <span>{{ $t('Home') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({name:'UserProfile'})"
        >
          <fa-icon
            icon="user"
            class="mr-50"
            pack="fas"
          />
          <span>{{ $t('Profile') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({name:'UserDevices'})"
        >
          <fa-icon
            icon="laptop"
            class="mr-50"
            pack="fas"
          />
          <span>{{ $t('Devices') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-navbar-nav
      v-else
      class="nav align-items-center ml-auto"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
        @click="goTo('profile')"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $t('Account') }}
            </p>
          </div>
          <fa-icon
            icon="user"
            pack="fas"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="goTo('login')"
        >
          <fa-icon
            class="mr-50"
            icon="sign-in-alt"
            pack="fas"
          />
          <span>{{ $t('Login') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="goTo('register')"
        >
          <fa-icon
            class="mr-50"
            icon="user-plus"
            pack="fas"
          />
          <span>{{ $t('Register') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      /* eslint-disable global-require */
      AllLocale: {
        en: {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          name: 'English',
        },
        ar: {
          locale: 'ar',
          img: require('@/assets/images/flags/ar.jpg'),
          name: 'Arabic',
        },
        tr: {
          locale: 'tr',
          img: require('@/assets/images/flags/tr.jpg'),
          name: 'Turkish',
        }
        ,
      },
    }
  },
  computed: {
    locales() {
      const locale = { ...this.AllLocale }
      delete locale[this.$i18n.locale]
      return locale
    },
    auth() {
      return this.$store.getters['auth/GetAuth']
    },
    authAvatar() {
      return this.$auth.GetAuthAvatar()
    },
    isAuth() {
      if (this.$auth.GetAuth()) return true
      return false
    },
  },
  methods: {
    changeLanguage(locale) {
      localStorage.setItem('local', locale)
      this.$i18n.locale = locale
      if (this.$i18n.locale === 'ar') {
        this.$vs.rtl = true
        this.$store.dispatch('appConfig/toggle_rtl', true)
      } else {
        this.$vs.rtl = false
        this.$store.dispatch('appConfig/toggle_rtl', false)
      }
    },
    getLocal() {
      return this.AllLocale[this.$i18n.locale].name
    },
    getLocalImage() {
      return this.AllLocale[this.$i18n.locale].img
    },
    goTo(val) {
      this.$router.push({
        name: val,
      })
    },
    logout() {
      this.$store.dispatch('auth/Logout', false)
      this.$router.push({
        name: 'login',
      })
    },
  },
}
</script>
